<template>
    <v-container fluid class="px-0 py-0 my-0 mx-0">

            <v-tabs
                v-model="tabs"
                fixed-tabs
                :right="!$vuetify.breakpoint.xsOnly"
            >
                <v-tab title="Маршрутный лист" v-if="!$vuetify.breakpoint.xsOnly">
                    <v-icon left>
                        mdi-car-shift-pattern
                    </v-icon>
                    Маршрутный лист
                </v-tab>
                <v-tab title="Передача на склад" v-if="!$vuetify.breakpoint.xsOnly">
                    <v-icon left>
                        mdi-warehouse
                    </v-icon>
                    Передача на склад
                </v-tab>

                <v-tab title="Маршрутный лист" v-if="$vuetify.breakpoint.xsOnly">
                    <v-icon left>
                        mdi-car-shift-pattern
                    </v-icon>
                </v-tab>
                <v-tab title="Передача на склад" v-if="$vuetify.breakpoint.xsOnly">
                    <v-icon left>
                        mdi-warehouse
                    </v-icon>
                </v-tab>


            </v-tabs>


            <v-tabs-items v-model="tabs" >
                <v-tab-item>
                    <v-card flat>
                        <v-card-text class="px-0 pt-0 pb-2 my-0 mx-0">

                            <v-row>
                                <v-col cols="12">
                                    <h3 class="headline pb-2 pl-3 pt-3">Нужно забрать</h3>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 py-0 my-0 mx-0" v-if="loading">
                                <v-col cols="12" class="mb-0 pb-0">
                                    <v-progress-linear :active="loading" indeterminate></v-progress-linear>

                                    <v-skeleton-loader v-if="loading_skeleton"
                                                       v-bind="attrs"
                                                       type="list-item-two-line, list-item-two-line, list-item-two-line"
                                    ></v-skeleton-loader>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 py-0 my-0">
                                <v-col cols="12" class="my-0 py-1 px-2" v-for="(item, index) in orderItems" :key="index">
                                    <v-hover v-slot="{ hover }" open-delay="300">
                                        <v-card class="mx-auto cursor-pointer" flat
                                                :elevation="hover ? 16 : 2"
                                                :class="{ 'on-hover': hover }"
                                        >
                                            <v-list-item three-line @click="showDetailsOrder(item)">
                                                <v-list-item-content class="pb-0">
                                                    <v-list-item-title class="text-h8 mb-1" >
                                                        {{ item.client_name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle >{{ item.address }}</v-list-item-subtitle>
                                                    <div class="text-overline small" >
                                                        {{ item.on_date }} {{ item.on_time !== '00:00' ? item.on_time : '' }}
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>


                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="9" class="pt-0" >
                                    <h3 class="headline pb-2 pl-3 pt-3">Нужно доставить</h3>
                                </v-col>

                                <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                    <v-btn @click="showScan" color="indigo" class="white--text">
                                        {{$t('create') }}
                                    </v-btn>
                                </v-col>
                                <v-col v-if="$vuetify.breakpoint.xsOnly" sm="2" class="text-right mr-2 pr-3 ">
                                    <v-btn @click="showScan()"
                                           color="primary" right class="white--text " >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 py-0 my-0 mx-0" v-if="loading">
                                <v-col cols="12" class="mb-0 pb-0">
                                    <v-progress-linear :active="loading" indeterminate></v-progress-linear>

                                    <v-skeleton-loader v-if="loading_skeleton"
                                                       v-bind="attrs"
                                                       type="list-item-two-line, list-item-two-line, list-item-two-line"
                                    ></v-skeleton-loader>
                                </v-col>
                            </v-row>
                            <v-row class="px-0 py-0 my-0">
                                <v-col cols="12" class="my-0 py-1 px-2" v-for="(item, index) in routerItems" :key="index">
                                    <v-hover v-slot="{ hover }" open-delay="300">
                                        <v-card class="mx-auto" flat
                                                :elevation="hover ? 16 : 2"
                                                :class="{ 'on-hover': hover }"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pb-0">
                                                    <v-list-item-title class="text-h8 mb-1" >
                                                        {{ item.recipient }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle >{{ item.address }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle >{{ item.phone }}</v-list-item-subtitle>
                                                    <div class="text-overline small" >
                                                        {{ item.number }}
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-col  cols="12" class="py-1 text-right">
                                                <template  class="text-right">
                                                    <v-tooltip bottom >
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" class="mr-3" @click="invoiceEdit(invoice.uuid)" :title="$t('edit')"
                                                            >
                                                                mdi-truck-check-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>{{$t('edit')}}</span>
                                                    </v-tooltip>
                                                </template>
                                            </v-col>


                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item class="px-0 py-0 my-0 mx-0">
                    <v-card flat class="px-0 py-0 my-0 mx-0">
                        <v-card-text class="px-0 py-0 my-0 mx-0">
                            <v-row class="px-0">
                                <v-col  cols="12" class="mb-0 pb-0">
                                    <v-progress-linear :active="loading" indeterminate></v-progress-linear>

                                    <v-skeleton-loader v-if="loading_skeleton"
                                                       v-bind="attrs"
                                                       type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                                    ></v-skeleton-loader>
                                </v-col>
                            </v-row>

                            <v-row class="px-0">
                                <v-col cols="12" class="px-2" v-for="(item, index) in orderItemsDo" :key="index">
                                    <v-hover v-slot="{ hover }" open-delay="300">
                                        <v-card class="mx-auto cursor-pointer" flat
                                                :elevation="hover ? 16 : 2"
                                                :class="{ 'on-hover': hover }"
                                        >
                                            <v-list-item three-line @click="showDetailsOrder(item)">
                                                <v-list-item-content class="pb-0">
                                                    <v-list-item-title class="text-h8 mb-1" >
                                                        {{ item.client_name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle >{{ item.address }}</v-list-item-subtitle>
                                                    <div class="text-overline small" >
                                                        {{ item.on_date }} {{ item.on_time !== '00:00' ? item.on_time : '' }}
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>


                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>





        <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelDetails()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name !== 'xs' ? 'text-right' : 'pb-0'">
                            <v-col cols="12" class="py-1"><b> {{ $t('number_order')}}: </b>{{ detailsItems.number ? detailsItems.number :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('on_date_order')}}: </b>{{ detailsItems.on_date ?  $moment(detailsItems.on_date).format('DD.MM.YYYY') :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('on_time_order')}}: </b>{{ detailsItems.on_time ? detailsItems.on_time :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('order_type')}}: </b> {{ detailsItems.order_type ? detailsItems.order_type :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('important')}}: </b> {{ ( detailsItems.important ? (( detailsItems.important == 1 ) ? $t('yes'): $t('no')) :  $t('no') )  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('company')}}: </b> {{ detailsItems.company ? detailsItems.company :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('class_order')}}: </b> {{ detailsItems.class ? detailsItems.class :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('summa')}}: </b> {{ detailsItems.summa ? detailsItems.summa :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('nature_of_cargo')}}: </b> {{ detailsItems.nature_of_cargo ? detailsItems.nature_of_cargo :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('contract')}}: </b> {{ detailsItems.contract ? detailsItems.contract :  $t('no_data')  }}</v-col>
                        </v-col>
                        <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
                            <v-col cols="12" class="py-1"><b>{{ $t('state')}}: </b>{{ detailsItems.state ? detailsItems.state :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b> {{ $t('fence_address')}}:</b> {{ detailsItems.address ? detailsItems.address :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('payment_order')}}: </b>{{ detailsItems.payer ? detailsItems.payer :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('courier')}}: </b> {{ detailsItems.courier ? detailsItems.courier :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('is_cash')}}: </b> {{ ( detailsItems.is_cash ? (( detailsItems.is_cash == 1 ) ? $t('yes'): $t('no')) :  $t('no') )  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('reason_text')}}: </b> {{ detailsItems.reason_text ? detailsItems.reason_text :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('description_order')}}: </b> {{ detailsItems.description ? detailsItems.description :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('sender')}}: </b> {{ detailsItems.sender ? detailsItems.sender :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('account_client_name')}}: </b> {{ detailsItems.client_name ? detailsItems.client_name :  $t('no_data')  }}</v-col>
                            <v-col cols="12" class="py-1"><b>{{ $t('phone')}}: </b> {{ detailsItems.phone ? detailsItems.phone :  $t('no_data')  }}</v-col>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row class="p-2 m-2">
                        <v-col cols="12" sm="6" v-for="(invoice, index) in detailsItems.invoices" :key="index">
                            <v-hover
                                v-slot="{ hover }"
                                open-delay="200"
                            >
                                <v-card
                                    :elevation="hover ? 16 : 2"
                                    :class="{ 'on-hover': hover }"
                                >
                                    <v-card-text>
                                        <p class="py-0 my-0"><b>{{ $t('number_invoice') }}:</b> {{ invoice.number }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('name_recipient') }}:</b> {{ invoice.name }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('phone_recipient') }}:</b> {{ invoice.phone }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('address_recipient') }}:</b> {{ invoice.address }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('weight') }}:</b> {{ invoice.weight }} {{ $t('kg') }}</p>
                                        <p class="py-0 my-0"><b>{{ $t('date') }}:</b>  {{  invoice.date }}</p>
                                        <!--<p class="py-0 my-0">{{ $t('title') }}: {{ invoice.uuid }}</p>-->
                                        <v-col  cols="12" class="py-1 text-right">
                                            <template  class="text-right">

                                                <v-tooltip bottom >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="mr-3" @click="invoiceEdit(invoice.uuid)" :title="$t('edit')"

                                                        >
                                                            mdi-square-edit-outline

                                                        </v-icon>
                                                    </template>
                                                    <span>{{$t('edit')}}</span>
                                                </v-tooltip>

                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on" class="mr-3" @click="invoiceCancel(invoice.uuid)" :title="$t('invoice_cancel')"
                                                        >
                                                            mdi-delete-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ $t('invoice_cancel') }}</span>
                                                </v-tooltip>





                                            </template>
                                        </v-col>

                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-if="dialogScan" v-model="dialogScan" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Сканировать накладные</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="cancelScan()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12"  style="text-align: center">
                            <StreamBarcodeReader class="align-center" ref="scanner" @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Добавленные накладные:</v-list-item-title>
                                    <v-list-item-subtitle v-for="(item, index) in invoiceItems" :key="index">{{ index + 1 }}) <b>{{ item }}</b></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn v-if="invoiceItems.length > 0 ? true: false"
                                width="100%"
                                color="primary"
                                   @click="createInvoices()"
                            >
                               Создать маршрутный лист
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

    import {mapGetters} from "vuex";
    import { StreamBarcodeReader } from "vue-barcode-reader";


    export default {
        name: "Routers",
        components: {
            StreamBarcodeReader,
        },
        data() {
            return {
                loading: false,
                progress: 0,
                loading_skeleton: false,
                orderItems: [],
                orderItemsDo: [],
                routerItems: [],
                invoiceItems: [],
                dialogDetails: false,
                dialogScan: false,
                tabs: null,
                text: '',
                attrs: {
                    class: 'mb-3',
                    boilerplate: true,
                    elevation: 2,
                },
            };
        },
        computed: {
            ...mapGetters(['lang']),
        },
        async mounted() {
            this.loading_skeleton = true
            await this.getRouters()
            this.loading_skeleton = false
        },
        beforeUnmount() {
            if(typeof (this.$refs.scanner) !== "undefined")
            {
                this.$refs.scanner.codeReader.reset();
            }
        },
        methods: {

            async getRouters() {
                this.loading = true;
                var _this = this
                let params = {};

                if (this.lang) {
                    params.language = this.lang
                }
                await this.$http
                    .get("courier/router", {
                        params: params,
                    })
                    .then(res => {
                        this.orderItems = res.body.data.invoices
                        this.orderItemsDo = res.body.data.invoices_do
                        this.routerItems = res.body.data.routes
                    })
                    .catch(err => {
                        this.orderItems = []
                        this.$toastr.error(this.$t('failed_to_get_list_routers'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    });
            },
            showDetailsOrder(item) {
                this.$router.push({
                    name: 'router.show',
                    params: {
                        uuid: item.uuid
                    }
                });
            },
            cancelDetails(){
                this.dialogDetails = false;
            },

            onLoaded(){
                console.log("loaded");
            },
            onDecode(text){
                let itm = null
                itm = this.invoiceItems.find(el => el === text)
                if(typeof (itm) === "undefined"){
                    this.invoiceItems.push(text)
                    this.invoiceItems.reverse()
                }
            },
            cancelScan(){
                if(typeof (this.$refs.scanner) !== "undefined")
                {
                    this.$refs.scanner.codeReader.reset();
                }
                this.dialogScan = false;

            },
            async showScan() {
                this.dialogScan = true;
            },
            async createInvoices() {
                var _this = this
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.language) {
                    formData.append('language', this.language)
                }

                if (this.invoiceItems && this.invoiceItems.length > 0) {
                    for (let i in this.invoiceItems) {
                        formData.append(`invoices[${i}]`, this.invoiceItems[i])
                    }
                }


                    await this.$http
                        .post('courier/router', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {

                            this.$toastr.success(this.$t('router_has_been_added'))
                            /*
                                this.$router.push({
                                    name: 'address'
                                })

                             */
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('router_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })

            },


      }
    };

</script>
